<template>
  <div>
    <b-modal
        v-model="showLocal"
        scrollable centered hide-footer no-close-on-backdrop
        dialog-class="servisniPripad-form"
        @hidden="$emit('close-modal')"
    >
      <template #modal-title>
        <div class="text-center">
          <h6 class="text-nowrap w-100">
            Servisní případ č. {{ kodZakazky }}</h6>
        </div>
      </template>

      <b-overlay :show="isLoading" rounded="sm">
        <div class="modalWindow-w100" @click.stop>
          <div class="line-info">
            <span class="">datum dokončení realizace (vystavby):</span> {{ getFormattedDate(dataForm.connectionDate) }}
          </div>
          <div class="line-info">
            <span class="">datum provedené technické prohlídky:</span>
          </div>
          <div class="line-info">
            <span class="">datum aktuální revize elektroinstalace:</span>
          </div>
          <div class="line-info pt-3 pb-2">
            <b-icon-plus-circle
                @click="addTicket"
                class="cursor-pointer fs-14px custom-link-black d-inline"
            />
            <span @click="addTicket" class="pl-1 bold">Přidat ticket</span>
          </div>

          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1" v-for="(ticket, indx) in tickets" :key="'ticket_' + indx" >
              <b-form @submit.stop.prevent="onSubmit(ticket)">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle:[`accord-${indx}`] :variant="getCardHeaderColor(ticket)" class="ticket-title" v-html="getTicketTitle(ticket)"></b-button>
                </b-card-header>
                <b-collapse :id="`accord-${indx}`" :visible="getVisibleTicket(indx, ticket)" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-form-textarea
                        :id="`comment-${indx}`"
                        v-model="ticket.comment"
                        placeholder=""
                        rows="3"
                        max-rows="3"
                        class="fs-14px"
                        :disabled="ticket.finished"
                    />
                    <div class="d-flex justify-content-between pt-2">
                      <div>
                        <b-icon-x-circle
                            v-if="!ticket.finished"
                            @click="confirmDeleteTicket(ticket, indx)"
                            class="cursor-pointer w-18px d-inline dark-red circle-icon"
                            font-scale="1.8"
                            title="Smazat servisní ticket"
                        />
                        <a href="#"
                           v-if="!ticket.finished"
                           @click="confirmDeleteTicket(ticket, indx)"
                           class="dark-red pl-1 v-align-super text-decoration-none"
                           title="Smazat servisní ticket"
                        >
                          <span class="color-inherit">Smazat</span>
                        </a>
                      </div>

                      <b-button type="submit" class="" size="sm" variant="primary">Uložit změny</b-button>

                      <b-form-checkbox
                          :id="`finished-${indx}`"
                          v-model="ticket.finished"
                          :name="`finished-${indx}`"
                      >
                        Splněno
                      </b-form-checkbox>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-form>
            </b-card>
          </div>
        </div>
      </b-overlay>
    </b-modal>

    <b-modal id="delete-service-ticket-confirmation-modal" centered hide-footer :title="getConfirmDeleteTicketModalTitle()" dialog-class="main-font mnogotochie delete-ticket" header-class="fs-13">
      <p class="my-3 bold">Opravdu chcete tento servisní ticket smazat?</p>
      <div class="d-flex justify-content-end pt-3">
        <b-button class="mr-3" variant="primary" @click="closeConfirmDeleteTicketModal">Zrušit</b-button>
        <b-button variant="outline-danger" @click="deleteTicket">Ano</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import commonMixin from "@/mixins/common.mixin.ts";
import axios from "axios";
import _ from "lodash";

const emptyTicket = {
  createdAt: new Date(),
  ticketNumber: null,
  comment: null,
  finished: false,
};

export default {
  props: {
    id: {type: String},
    show: {type: Boolean},
    kodZakazky: {type: String},
  },
  mixins: [commonMixin],
  computed: {
  },
  data() {
    return {
      isLoading: false,
      showLocal: false,
      tickets: [],
      deletingTicket: null,
      dataForm: {
        connectionDate: null,
      },
    }
  },
  methods: {
    addTicket() {
      this.tickets.unshift(_.cloneDeep(emptyTicket));
    },
    async deleteTicket() {
      try {
        const indx = this.deletingTicket.indx;
        if (!this.deletingTicket?.ticketNumber) {
          this.tickets.splice(indx, 1);
          this.closeConfirmDeleteTicketModal();
          return;
        }

        const url = `/service/deleteServiceTicket`;
        const {data: {data}} = await axios.get(url, {params: {id: this.deletingTicket._id}});
        if (data?.success) {
          this.$snotify['success']('Servisní ticket byl smazán');
        }
        this.tickets.splice(indx, 1);
        this.closeConfirmDeleteTicketModal();
        await this.getSavedData();
      } catch (e) {
        console.error('ServisniPripadForm deleteTicket', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    confirmDeleteTicket(ticket, indx) {
      this.deletingTicket = ticket;
      this.deletingTicket.indx = indx;
      this.$bvModal.show('delete-service-ticket-confirmation-modal');
    },
    closeConfirmDeleteTicketModal() {
      this.deletingTicket = null;
      this.$bvModal.hide('delete-service-ticket-confirmation-modal');
    },
    getConfirmDeleteTicketModalTitle() {
      if (!this.deletingTicket) return '';
      const dateZalozeni = `datum založení: ${this.getFormattedDate(this.deletingTicket.createdAt)}`;
      return this.deletingTicket?.ticketNumber ? `Servisní ticket č. ${this.getTicketNumberStr(this.deletingTicket.ticketNumber)} / ${dateZalozeni}` : `Nový ticket  / ${dateZalozeni}`;
    },
    getTicketTitle(ticket) {
      const dateZalozeni = `<span class="fs-13px">datum založení: ${this.getFormattedDate(ticket.createdAt)}</span>`;
      return ticket.ticketNumber ? `servisní ticket č. ${this.getTicketNumberStr(ticket.ticketNumber)} / ${dateZalozeni}` : `Nový ticket  / ${dateZalozeni}`;
    },
    getState({invalid, validated}, skip = false) {
      if (skip) return null;
      return validated ? !invalid : null;
    },
    async getSavedData() {
      try {
        const url = `/service/getServisniPripadInfo`;
        const {data: {data}} = await axios.get(url, {params: {id: this.id}});
        const info = data?.info || {};
        this.dataForm = {...this.dataForm, ...info};
      } catch (e) {
        console.error('ServisniPripadForm getSavedData', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async onSubmit(ticket) {
      try {
        const url = `/service/saveServiceTicket`;
        await axios.post(url, { kodZakazky: this.kodZakazky, ticket });
        await this.getServiceTickets();
        this.$snotify['success']('Ticket byl uložen');
      } catch (e) {
        console.error('ServisniPripadForm onSubmit', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async getServiceTickets() {
      try {
        const url = `/service/getServiceTickets`;
        const {data: {data}} = await axios.get(url, {params: { kodZakazky: this.kodZakazky }});
        this.tickets = data?.tickets || [];
      } catch (e) {
        console.error('ServisniPripadForm getServiceTickets', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    getTicketNumberStr(ticketNumber) {
      const ticketNumberStr = ticketNumber ? ticketNumber.toString().padStart(6, '0') : '';
      return ticketNumberStr.replace(/(\d{3})(\d{3})/, '$1 $2');
    },
    getVisibleTicket(indx, ticket) {
      if (!indx ) return true;
      return !ticket.ticketNumber;
    },
    getCardHeaderColor(ticket) {
      return ticket.finished ? 'light' : 'info';
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getSavedData();
    await this.getServiceTickets();
    this.isLoading = false;
    this.showLocal = this.show;
  },

}
</script>

<style scoped>
 .ticket-title {
   padding: 0 0.75rem;
   text-align: left;
 }

</style>