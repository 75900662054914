<template>
  <div v-if="$store.getters.isServisPageAllowed" class="pl-2 pr-2 h-max-content">
    <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
      <div class="text-left button-line pl-1">
        <b-btn
          variant="outline-primary"
          disabled
        >
          Nový servisní případ
        </b-btn>
      </div>

      <div class="text-left filter-line d-flex">
        <span class="filtry-text">Filtry:</span>
        <b-form-select
            name="filterTypes"
            v-model="filterType"
            :options="listFilterTypes"
            class="form-select form-select-sm d-inline-block w-230px text-black"
            size="sm"
            @change="changeFilterTypes"
        />

        <template v-if="isFilterTypeNazev || isFilterZip || isFilterPhone">
          <b-form-input
              id="searchString"
              type="text"
              size="sm"
              v-model.trim="filter.searchString"
              class="d-inline-block w-250px ml-3 text-black"
              :class="[filter.searchString ? 'input-filtered' : '']"
              placeholder="napište co hledáte..."
              v-debounce="searchByString"
          />
        </template>
        <template v-else-if="isFilterTypeObject">
          <b-form-select
            name="object"
            v-model="objectOption"
            :options="lists.object"
            class="d-inline-block w-170px ml-3 form-select form-select-sm"
            size="sm"
            @change="changeObjectSelect"
          >
            <template #first>
              <option value=""></option>
            </template>
          </b-form-select>
        </template>
        <template v-else-if="isFilterTypeKategory">
          <b-form-select
            name="kategory"
            v-model="filter.kategory"
            :options="lists.kategory"
            class="d-inline-block w-170px ml-3 form-select form-select-sm"
            size="sm"
            @change="changeTypKategory"
          >
            <template #first>
              <option value=""></option>
            </template>
          </b-form-select>
        </template>
        <template v-else-if="isFilterTypeStav">
        <b-form-select
          name="stav"
          v-model="stavOption"
          :options="lists.stav"
          class="d-inline-block w-250px ml-3 form-select form-select-sm"
          size="sm"
          @change="changeStavSelect"
        >
          <template #first>
            <option value=""></option>
          </template>
        </b-form-select>
      </template>
        <template v-else-if="isFilterTypeOkres">
          <b-form-input
            list="okresOPList"
            name="okresOP"
            class="form-control form-control-sm w-250px ml-3 text-black"
            v-model="filter.okresOP"
            @change="changeOkresSelect"
          />
          <b-datalist
            id="okresOPList"
            :options="lists.okresy"
            autocomplete="off"
          />
        </template>
        <template v-else-if="isFilterTypeKraj">
          <b-form-select
              name="stav"
              v-model="krajOption"
              :options="KRAJ_SHORT"
              class="d-inline-block w-250px ml-3 form-select form-select-sm"
              size="sm"
              @change="changeKrajSelect"
          >
            <template #first>
              <option value=""></option>
            </template>
          </b-form-select>
        </template>

        <b-icon-x-circle
            @click="clearFilter"
            class="pl-2 pt-1 cursor-pointer"
            :class="[filter.searchString || krajOption ? 'filtered' : '']"
            font-scale="1.8"
            title="Čištění filtru"
        />
        <span class="pl-5 pt-3px">
            [ servisních případů: {{ totalItems }} ]
        </span>
      </div>

      <b-table
          sticky-header="calc(100vh - 14.5rem)"
          bordered
          responsive
          hover
          no-border-collapse
          :items="listServices"
          :fields="headers"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-direction="desc"
          thead-tr-class="align-middle"
          no-local-sorting
          @sort-changed="changeOrder"
          class="stickyTable border-bottom"
      >

        <template #cell(kartaConnectionDate)="{ item }">
          <div class="text-center">
            <b-icon-journal
                font-scale="1.3"
                variant="secondary"
                class="cursor-pointer"
                @click="showKartaModal(item)"
            />
          </div>
          <div class="fs-11px pt-1">
            {{ item.connectionDate ? `${getFormattedDate(item.connectionDate)}` : ''}}
          </div>
        </template>

        <template #cell(orderNumber)="{ item }" class="color1 bg-color-white">
          <div class="d-flex justify-content-between text-left">
            <template v-if="$store.getters.isServisniPripadAllowed">
              <div>
                <b-icon-clipboard-plus
                    class="user-enabled-email cursor-pointer"
                    v-b-tooltip.hover="{
                        title: 'Kopírovat název',
                        delay: { show: 200, hide: 200 },
                        variant: 'secondary'
                      }"
                    @click="copyOP(item)"
                />
                <a
                    href="#"
                    class="pl-2"
                    @click.prevent="showServisniPripadFormModal(item)"
                    title="Otevřít 'Servisní případ' formulář"
                >
                  {{ item.orderNumber }}
                </a>
              </div>
              <a
                  :href="getMailTo(item)"
                  title="Poslat email"
                  class="pl-3"
              >
                <b-icon-envelope-fill class="user-enabled-email"/>
              </a>
            </template>
            <span v-else>
              {{ item.orderNumber }}
            </span>
          </div>
        </template>

        <template #cell(adresaInstalace)="{ item }" class="color1">
          <a
            v-if="$store.getters.isObhlidkovyFormularModalAllowed"
            :class="{ 'text-black': isObhlidkovyFormularFormSaved(item) }" href="#"
            @click.prevent="showObhlidkovyFormularFormModal(item)"
            :title="isObhlidkovyFormularFormSaved(item) ? 'Upravit «Obhlídkový formulář»' : 'Vyplnit «Obhlídkový formulář»'"
          >
          <div class="text-left">
            {{ getFullAddress(item) }}
          </div>
          </a>
          <div v-else class="text-left">
            {{ getFullAddress(item) }}
          </div>
          <div class="text-left">
            <span class="fs-13px">{{ getKrajNameAndOkres(item) }}</span>
            </div>
        </template>

        <template #cell(zakaznik)="{ item }">
          <div class="text-center">
            <a
                v-if="$store.getters.isGDPRServiceAllowed"
                @click.prevent="showGDPRRodinnePrislusniciModal(item)"
                title="Otevřít 'Kontaktní údaje k obchodnímu případu' formulář"
                class="color-black"
                href="#"
            >
              <span v-if="isPersonalInfoFilled(item)">
                {{ joinName(item.personaInfo) }}
              </span>
              <b-icon-pencil-square v-else/>
            </a>
            <span v-else>
              {{ joinName(item.personaInfo) }}
            </span>
          </div>
          <div v-if="item.personaInfo.phone" class="text-center">
            <span class="fs-12px">(tel. {{ item.personaInfo.phone }})</span>
          </div>
        </template>

        <template #cell(poznamky)="{ item }" class="color1">
          <div class="text-center">
            <a v-if="$store.getters.isPoznamkyKservisuAllowed"
               @click.prevent="showPoznamkyKservisuModal(item)"
               title="Otevřít 'Poznámky k servisu' formulář"
               href="#"
            >
                  <span v-if="isShowPoznamkySaveDate(item)">
                    {{ getFormattedDate(item.obchod.poznamkyServiceSaveDate) }}
                  </span>
              <b-icon-pencil-square v-else/>
            </a>
            <span v-else>
                    {{ getFormattedDate(item.obchod.poznamkyServiceSaveDate) }}
                </span>
          </div>
        </template>

        <template #head()="{field}">
          <span v-if="field.num" class="rowNumber">{{ field.num }}</span>
          <span v-html="field.label"></span>
        </template>

      </b-table>

      <div v-if="!listServices.length" class="pt-5">
        <h5 class="p-5 fs-23px">Žádné výsledky vyhledávání</h5>
      </div>

      <gpdr-form
          v-if="showGDPRForm && $store.getters.isGDPRServiceAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showGDPRForm"
          @close-modal="showGDPRForm = false"
          @form-saved="formSaved"
      />

      <karta-form
          v-if="showKartaForm"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showKartaForm"
          @close-modal="showKartaForm = false"
      />

      <poznamky-k-servisu-form
        v-if="showPoznamkyKservisuForm && $store.getters.isPoznamkyKservisuAllowed"
        :id="getIdSelectedContract"
        :kodZakazky="selectedContract.kodZakazky"
        :show="showPoznamkyKservisuForm"
        @close-modal="showPoznamkyKservisuForm = false"
        @form-saved="formSaved"
        :section="SECTION_NAMES.SERVIS"
      />

      <obhlidkovy-formular-form
        v-if="showObhlidkovyFormularForm && $store.getters.isObhlidkovyFormularModalAllowed"
        :id="getIdSelectedContract"
        :show="showObhlidkovyFormularForm"
        :first-name="selectedContract.clientName"
        :last-name="selectedContract.clientLastName"
        @close-modal="showObhlidkovyFormularForm = false"
        @show-vlozit-soubory-foto-modal="showVlozitSouboryFotoModal"
        @form-saved="formSaved"
      />

      <servisni-pripad-form
        v-if="showServisniPripadForm && $store.getters.isServisniPripadAllowed"
        :id="getIdSelectedContract"
        :kodZakazky="selectedContract.kodZakazky"
        :show="showServisniPripadForm"
        @close-modal="showServisniPripadForm = false"
        @form-saved="formSaved"
      />
    </b-overlay>
  </div>
</template>


<script>

import axios from "axios";
import commonMixin from "@/mixins/common.mixin.ts";
import GpdrForm from "@/components/modals/GDPRForm.vue";
import PoznamkyKServisuForm from "@/components/modals/PoznamkyPripojit.vue";
import ObhlidkovyFormularForm from "@/components/modals/ObhlidkovyFormularForm.vue";
import ServisniPripadForm from "@/components/modals/ServisniPripadForm.vue";
import {
  FILTER_TYPES, LIST_SERVIS_FILTER_TYPES, KRAJ, KRAJ_SHORT, PRIPAD_OBJECTS, PRIPAD_CATEGORIES, SECTION_NAMES,
} from "@/constants/form.constants";
import KartaForm from "@/components/modals/KartaForm.vue";
import mailtoLink from "mailto-link";
import _ from "lodash";

const defaultSortDirection = true;

export default {
  components: {
    KartaForm,
    GpdrForm,
    PoznamkyKServisuForm,
    ObhlidkovyFormularForm,
    ServisniPripadForm,
  },
  computed: {
    SECTION_NAMES() {
      return SECTION_NAMES
    },
    isFilterTypeNazev() {
      return this.filterType === FILTER_TYPES.NAZEV_V_ADRESARE;
    },
    isFilterTypeObject() {
      return this.filterType === FILTER_TYPES.OBJECT_SERVISNIHO_PRIPADU;
    },
    isFilterTypeKategory() {
      return this.filterType === FILTER_TYPES.KATEGORIE_PRIPADU;
    },
    isFilterTypeStav() {
      return this.filterType === FILTER_TYPES.STAV_SERVISNIHO_PRIPADU;
    },
    isFilterTypeOkres() {
      return this.filterType === FILTER_TYPES.OKRES_SERVISNIHO_PRIPADU;
    },
    isFilterTypeKraj() {
      return this.filterType === FILTER_TYPES.KRAJ_SERVISNIHO_PRIPADU;
    },
    isFilterZip() {
      return this.filterType === FILTER_TYPES.ZIP;
    },
    isFilterPhone() {
      return this.filterType === FILTER_TYPES.PHONE;
    },
    getIdSelectedContract() {
      return this.selectedContract?._id;
    },
  },
  mixins: [commonMixin],
  data() {
    return {
      KRAJ,
      KRAJ_SHORT,
      isLoading: false,
      listServices: [],
      showModal: false,
      showKartaForm: false,
      showPoznamkyKservisuForm: false,
      showObhlidkovyFormularForm: false,
      showStavMultifilterForm: false,
      showServisniPripadForm: false,
      selectedContract: null,
      obchodniZastupceName: null,
      headers: [
        {label: 'Karta servisu<br><span class="font-weight-normal">(datum realizace)</span>', key: 'kartaConnectionDate', variant: 'color-5 mw-90px max-w-90px', sortable: true},
        {label: 'Číslo zakázky (název adresáře v zakázkách)', key: 'orderNumber', num: 1, stickyColumn: true, variant: 'color-5', class: 'mw-300px stickyColumn', sortable: true},
        {label: 'Zákazník<br><span class="font-weight-normal">(kontaktní údaje)</span>', key: 'zakaznik', num: 2, variant: 'color-5', class: 'mw-200px', sortable: true},
        {label: 'Adresa instalace<br><span class="font-weight-normal">(obhlídkový formulář)</span>', key: 'adresaInstalace', num: 3, variant: 'color-5', class: 'mw-300px', sortable: true},
        {label: 'Poznámky<br><span class="font-weight-normal">(textový popis servisu)</span>', key: 'poznamky', num: 4, variant: 'color-5 mw-115px', sortable: true},
        {label: 'Datum dokončení realizace FVE', key: 'connectionDate', num: 5, variant: 'color-5', formatter: cur => this.getFormattedDate(cur), sortable: true},
        {label: 'Počet dní po připojení FVE', key: 'pocetDniPripojeniFVE', num: 6, variant: 'color-5', sortable: true},
      ],
      filter: {
        name: null,
        searchString: null,
        sortBy: null,
        sortDirection: defaultSortDirection,
        okresOP: null,
        obchodniZastupceName: null,
        kategory: null,
      },
      sortBy: 'pocetDniPripojeniFVE',
      sortDesc: true,
      limit: 30,
      offset: 0,
      lastScrollTop: 0,
      listFilterTypes: this.getFilteredFilterTypes(),
      filterType: FILTER_TYPES.NAZEV_V_ADRESARE,
      showGDPRForm: false,
      stavOption: '',
      krajOption: '',
      objectOption: '',
      abortController: new AbortController(),
      abortControllerCount: new AbortController(),
      lists: {
        stav: [],
        object: [],
        okresy: [],
        kategory: PRIPAD_CATEGORIES,
      },
      totalItems: 0,
    }
  },
  methods: {
    async getListService() {
      this.abortController.abort();
      this.abortController = new AbortController();

      try {
        this.isLoading = true;
        const url = `/service/getListServices`;
        const {data: {data}} = await axios.post(url, {
              offset: this.offset,
              limit: this.limit,
              filter: this.filter,
            },
            {signal: this.abortController.signal}
        );
        if (!this.offset) {
          this.listServices = data;
        } else {
          this.listServices.push(...data);
        }
        this.offset = this.offset > this.listServices.length ? this.offset : this.limit + this.offset;
        this.loading = false;
        this.isLoading = false;
      } catch (e) {
        if (!e?.response?.data?.message) {
          // console.error('Operation canceled');
        } else {
          console.error('getListService', e.response?.data);
          this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
          this.isLoading = false;
        }
      }
    },
    async getInfo(noCountQuery = false) {
      await Promise.all([
        this.getListService(noCountQuery),
        this.getServicesCount(noCountQuery),
      ]);
    },
    async getServicesCount(noCountQuery) {
      try {
        if (noCountQuery) return;

        this.abortControllerCount.abort();
        this.abortControllerCount = new AbortController();

        const url = `/service/getServicesCount`;
        const {data: {data}} = await axios.post(url, {
          filter: this.filter,
        }, {signal: this.abortControllerCount.signal});
        this.totalItems = data?.total || 0;
      } catch (e) {
        console.error('getServicesCount', e);
        throw new Error()
      }
    },
    async handleScroll() {
      const tableEl = this.$el.querySelector('.table-responsive');
      const currentScrollTop = tableEl.scrollTop;
      if (currentScrollTop > this.lastScrollTop && (currentScrollTop + tableEl.offsetHeight + 5 >= tableEl.scrollHeight) && !this.loading) {
        this.loading = true;
        await this.getInfo(true);
      }
      this.lastScrollTop = currentScrollTop;
    },
    async searchByString() {
      this.offset = 0;
      this.filter.name = this.filterType;
      return await this.getInfo();
    },
    async clearFilter() {
      this.offset = 0;
      this.filter.searchString = null;
      this.krajOption = null;
      this.filter.okresOP = null;
      this.filter.kategory = null;
      this.stavOption = null;
      this.krajOption = null;
      this.objectOption = null;
      this.resetScrollTop();
      await this.getInfo();
    },
    getFilteredFilterTypes() {
      return LIST_SERVIS_FILTER_TYPES;
    },
    async changeFilterTypes() {
      switch (this.filterType) {
        case FILTER_TYPES.OZ_USERS_ACTIVE:
          this.ozUsers = this.activeOzUsersInfo;
          this.ozUsersNames = this.activeOzUsersNames;
          break;
        case FILTER_TYPES.OZ_USERS_DEACTIVATED:
          this.ozUsers = this.deactivatedOzUsersInfo;
          this.ozUsersNames = this.deactivatedOzUsersNames;
          break;
      }
      await this.clearFilter();
    },
    async changeOrder(ctx) {
      this.filter.sortBy = ctx.sortBy;
      this.filter.sortDirection = ctx.sortDesc;
      this.offset = 0;
      this.resetScrollTop();
      await this.getInfo();
    },
    showGDPRRodinnePrislusniciModal(item) {
      this.selectedContract = item;
      this.showGDPRForm = true;
    },
    showPoznamkyKservisuModal(item) {
      this.selectedContract = item;
      this.showPoznamkyKservisuForm = true;
    },
    showObhlidkovyFormularFormModal(item) {
      this.selectedContract = item;
      this.showObhlidkovyFormularForm = true;
    },
    showServisniPripadFormModal(item) {
      this.selectedContract = item;
      this.showServisniPripadForm = true;
    },
    showVlozitSouboryFotoModal() {
      // this.showVlozitSouboryFotoForm = true;
    },
    isObhlidkovyFormularFormSaved(item) {
      return !!item.clientPhone;
    },
    getKrajNameAndOkres(item) {
      let result = [];
      result.push(this.getKrajName(item.kraj, this.KRAJ));
      result.push(this.getOkres(item));
      result = result.filter(r => r);
      return result.length ? `(${result.join(', ')})` : '';
    },
    async changeSelectList(searchString, filterName) {
      this.offset = 0;
      this.filter.name = filterName;
      this.filter.searchString = searchString;
      this.resetScrollTop();
      await this.getInfo();
    },
    async changeObjectSelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.OBJECT_OBCHODNIHO_PRIPADU);
    },
    async changeTypKategory(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.KATEGORIE_PRIPADU);
    },
    async changeStavSelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.STAV_OBCHODNIHO_PRIPADU);
    },
    async changeKrajSelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU);
    },
    async changeOkresSelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.OKRES_OP);
    },
    async formSaved(/*type = null, kodZakazky = null*/) {
      this.offset = 0;
      await this.getInfo();
    },
    showKartaModal(item) {
      this.selectedContract = item;
      this.showKartaForm = true;
    },
    getMailTo(item) {
      const body = `
Dobrý den.

Od firmy Tri energo, s.r.o. máte na střeše fotovoltaickou elektrárnu:

panely - ${item.numberOfPanels}x ${item.panelType}
počet stringů - ${item.numberOfThongs}${this.getPocetOptimizeru(item)}
typ střídače - ${item.inverterType}${this.getTypBaterie(item)}
příslušenství - ${item.accessories}${this.getNabiecka(item)}

Snad to Vám funguje skvěle a spolehlivě. Ale uběhlo více než 2 roky od montáže nebo posledního servisu Vaší FVE. Doporučujeme odborně zkontrolovat celou elektrárnu.

Více informací a objednávkový formulář naleznete zde:
https://www.trienergo.cz/servis

Datum dokončení instalace:

Datum posledního servisu:

V rámci naší servisní kontroly provádíme:

   • kontrola spojů (termokamerou) a jejich dotažení
   • optická kontrola FV panelů (dronem bude-li třeba)
   • kontrola stavu přepěťových ochran (kontakty / terče)
   • kontrola značení (výstražné a popisné nálepky)
   • kontrola všech FI ochran (jejich test)
   • kontrola kotvení elektrárny (držáky na zdi apod.)
   • analýza chodu FVE ze vzdáleného dohledu
   • kontrola Wattrouteru / AZ routeru (skrze PC)
   • kontrola nabíječky elektromobilu (spoje, kabely, dohled)


S přáním hezkého dne
`;

      return  mailtoLink({
        to: item?.personaEmail || '',
        body,
      });
    },
    getBatteryCount(item) {
      return _.get(item, 'battery.count', '');
    },
    getBatteryType(item) {
      return _.get(item, 'battery.type', '');
    },
    getPocetOptimizeru(item) {
      return item?.optimizers ? `, počet optimizérů - ${item.optimizers}` : '';
    },
    getTypBaterie(item) {
      return this.getBatteryCount(item) ? `
typ baterie - ${this.getBatteryCount(item)}x ${this.getBatteryType(item)}` : '';
    },
    getNabiecka(item) {
      return item?.charger ? `, nabíječka - ${item.charger}` : '';
    },
    isPersonalInfoFilled(item) {
      const name = this.joinName(item.personaInfo);
      return !!name;
    },
    isShowPoznamkySaveDate(item) {
      return !!_.get(item, 'obchod.poznamkyServiceSaveDate', false);
    },
    async getConstants() {
      try {
        const dataConst = await this.getConstantsFromServer(['STAV_STATUSES']) || {};
        this.lists.stav = dataConst.STAV_STATUSES;
        this.lists.object = PRIPAD_OBJECTS;
      } catch (e) {
        console.error('getConstants', e);
      }
    },
    async getOkresy() {
      try {
        this.lists.okresy = await this.getOkresyList();
      } catch (error) {
        console.log(error);
      }
    },
    copyOP(item) {
      this.copyToClipboard(item.orderNumber);
    },
    copyToClipboard(text) {
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$snotify['success']('Text byl zkopírován do schránky');
    },
  },

  async mounted() {
    this.isLoading = true;
    this.filter.name = FILTER_TYPES.NAZEV_V_ADRESARE;
    await this.getConstants();
    await Promise.all([
      this.getInfo(),
      this.getOkresy()
    ]);

    const table = this.$el?.querySelector('.table-responsive');
    if (table) {
      table.addEventListener('scroll', this.handleScroll);
    }
    this.isLoading = false;
  },
  beforeDestroy() {
    if (typeof this.$el === 'object' && this.$el !== null && 'querySelectorAll' in this.$el) {
      this.$el.querySelector('.table-responsive').removeEventListener('scroll', this.handleScroll);
    }
  },
};
</script>

<style scoped>
.rotate > div {
  transform: rotate(-90deg);
}
.deadline > div {
  font-weight: normal;
  font-size: 13px;
}
.table-bordered th, .table-bordered td {
  border: 1px solid black !important;
}

</style>
